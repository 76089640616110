import React, { useState, useEffect } from 'react';
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, 
  TextField,
  Box,
  Stack,
  Alert,
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CancelIcon from '@mui/icons-material/Cancel';

const UserEditor = ({ dialogOpen, handleDialogClose }) => {
  const auth = useAuth();

  const [nickName, setNickName] = useState(auth.user.nickName);
  const [nickNameError, setNickNameError] = useState('');
  const [nickNameValidationStatus, setNickNameValidationStatus] = useState('');
  const [nickNameSavingStatus, setNickNameSavingStatus] = useState('');

  const handleNickNameChange = e => {
    setNickName(e.target.value);
  }

  useEffect(() => {
    if (nickNameValidationStatus === 'unavailable') {
      setNickNameError('Nimimerkki on jo varattu, valitse toinen nimimerkki.');
    }
    if (nickNameValidationStatus === 'available') {
      setNickNameError('');
      auth.setNickName(nickName, setNickNameSavingStatus);
    }
  }, [nickNameValidationStatus])

  const validateNickNameOnBlur = () => {
    setNickNameSavingStatus('');
    if (nickName.length < 3) {
      setNickNameError('Nimimerkin tulee olla vähintään kolme merkkiä pitkä');
      return false;
    }
    if (nickName.length > 15) {
      setNickNameError('Nimimerkin tulee olla enintään 15 merkkiä pitkä');
      return false;
    }

    setNickNameError('');
    return true;
  }

  const submitNickName = () => {
    setNickNameSavingStatus('');
    if (nickName.length < 3) {
      setNickNameError('Nimimerkin tulee olla vähintään kolme merkkiä pitkä');
      return false;
    }
    if (nickName.length > 15) {
      setNickNameError('Nimimerkin tulee olla enintään 15 merkkiä pitkä');
      return false;
    }
    if (nickName === auth.user.nickName) {
      setNickNameError('Nimimerkki ei voi olla sama kuin nykyinen nimimerkkisi.');
      return false;
    }

    setNickNameError('');

    setNickNameValidationStatus('pending');
    auth.isNickNameAvailable(nickName, setNickNameValidationStatus);

    return true;
  }


  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="Käyttäjätiedot"
      aria-describedby="Käyttäjätiedot"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        Käyttäjätiedot
      </DialogTitle>
      <DialogContent>
        <Box mt={1}/>
        {auth.user.email}
        <Box mt={2}/>
        <Stack direction={'row'} spacing={1}>
          <TextField
            id='subject'
            label='Nimimerkki'
            onChange={handleNickNameChange}
            onBlur={validateNickNameOnBlur}
            value={nickName}
            sx={{width: '100%', background: 'white'}}
          />
          <Button 
            disabled={nickNameValidationStatus === 'pending' || nickNameSavingStatus === 'pending'}
            onClick={submitNickName}>
              <SaveAltIcon />
          </Button>
          <Button onClick={() => setNickName(auth.user.nickName)}>
            <CancelIcon />
          </Button>
        </Stack>

        {nickNameError.length > 0 && (
          <>
            <Box mt={2} />
            <Alert severity='warning'>{nickNameError}</Alert>
          </>
        )}
        {nickNameSavingStatus === 'error' && (
          <>
            <Box mt={2} />
            <Alert severity='error'>Nimimerkin asettaminen epäonnistui teknisen virheen vuoksi.</Alert>
          </>
        )}
        {nickNameSavingStatus === 'saved' && (
          <>
            <Box mt={2} />
            <Alert severity='success'>Nimimerkki asetettu!</Alert>
          </>
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>
          SULJE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditor;