import React, {useState, useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../hooks/useAuth';
import verttiApi from '../../api/verttiApi';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Backdrop, LinearProgress, Fade, Modal } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';


// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: '100vw', md: '90vw', xl: '80vw'},
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
  m: 0,
};

const ImageToolBar = ({ photo, updatePhotoLike, displayDialog }) => {
  const auth = useAuth();
  const [likeLoading, setLikeLoading] = useState(false);

  const likePhoto = (photoId) => {
    if (likeLoading) return false;

    if (!auth.user) {
      displayDialog('login_required');
      return false;
    }

    setLikeLoading(true);

    updatePhotoLike(photoId, true);

    verttiApi.get(`/photo/${photoId}/like/1`)
      .then(res => {
        setLikeLoading(false);
      })
      .catch(err => {
        updatePhotoLike(photoId, false);
        setLikeLoading(false);
      })
  }

  const unLikePhoto = (photoId) => {
    if (likeLoading) return false;

    if (!auth.user) {
      displayDialog('login_required');
      return false;
    }

    setLikeLoading(true);

    updatePhotoLike(photoId, false);

    verttiApi.get(`/photo/${photoId}/like/0`)
      .then(res => {
        setLikeLoading(false);
      })
      .catch(err => {
        updatePhotoLike(photoId, true);
        setLikeLoading(false);
      })
  }

  return (
    <Box mt={1} display='flex' justifyContent='space-between' width='100%'>
      <Box display='flex'>
        {photo.liked && 
          <FavoriteIcon 
            style={{ color: 'red', cursor: 'hand' }}
            onClick={() => unLikePhoto(photo.publicId)}
          /> 
        }
        {!photo.liked && 
          <FavoriteBorderIcon 
            style={{cursor: 'hand'}}
            onClick={() => likePhoto(photo.publicId)}
          /> 
        }
        {/* <Tooltip title='Raportoi ongelmasta'>
          <ReportGmailerrorredIcon />
        </Tooltip> */}
      </Box>
      <Box>
        <Typography variant='small'>🙏 {photo.uploader === 'n/a' ? 'Anonyymi' : photo.uploader}</Typography>
      </Box>
    </Box>
  );
}

const FullScreenImage = ({ url, open, handleClose }) => {
  return (

    <Modal
      aria-labelledby="suuri kuva"
      aria-describedby="suuri kuva"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Box
            component="img"
            sx={{
              maxWidth: '100%',
            }}
            src={url}
            onClick={() => handleClose()}
          />
        </Box>
      </Fade>
    </Modal>
  );
}

const ImageCarousel = ({ images, updatePhotoLike, displayDialog }) => {
  const theme = useTheme();
  const [displayImageUrl, setDisplayImageUrl] = React.useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(5);
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  }

  useEffect(() => {
    const imageUrls = images.map(image => `${process.env.REACT_APP_CDN_URL}/${image.url}`);
    let loadedImages = 0;
  
    const updateProgress = () => {
      loadedImages++;
      setLoadingProgress((loadedImages / imageUrls.length) * 100);
      if (loadedImages === imageUrls.length) {
        setIsLoading(false);
      }
    };
  
    const loadImages = imageUrls.map(url => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          updateProgress();
          resolve();
        };
        img.onerror = () => {
          updateProgress(); // Optionally handle errors differently
          reject();
        };
      });
    });
  
    Promise.all(loadImages).catch(err => console.log("Error loading images", err));
  }, [images]);

  if (!images ) return <></>;

  if (isLoading) return (
      <Box my={5}>
        <LinearProgress variant="determinate" value={loadingProgress} />
      </Box>
  );

  const displayFullScreen = (url) => {
    setDisplayImageUrl(url);
    setModalOpen(true);
  }

  return (
    <>
      <Box my={2} sx={{ maxWidth: '100%', flexGrow: 1 }}>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
        >
          {images.map((image) => (
            <SwiperSlide key={image.publicId}>
              <Box
                component="img"
                sx={{
                  width: '100%',
                  display: 'block',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  objectFit: 'cover',
                }}
                src={`${process.env.REACT_APP_CDN_URL}/${image.url}`}
                alt={image.label}
                onClick={() => displayFullScreen(`${process.env.REACT_APP_CDN_URL}/${image.url}`)}
              />
              <ImageToolBar 
                photo={image} 
                updatePhotoLike={updatePhotoLike} 
                displayDialog={displayDialog}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <FullScreenImage open={modalOpen} handleClose={handleModalClose} url={displayImageUrl} />
    </>
  );
}

export default ImageCarousel;
