import React, {useState, useMemo, useEffect, useRef} from 'react';
import { Box, Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, TextField, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio, Grow, LinearProgress, Alert } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// import * as yup from "yup";
import { useFormik } from 'formik';
import verttiApi from '../../api/verttiApi';
import { withSnackbar } from 'notistack';
import { useSnackbar } from 'notistack';
import {useDropzone} from 'react-dropzone';
import { useAuth } from '../../hooks/useAuth';
import ImageCarouselHarborAdmin from '../map/ImageCarouselHarborAdmin';
import HarborEntitlementsManager from './HarborEntitlementsManager';

// import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
// import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';

const harborServices = {
  'drinkingWater': {labelId: 'service.harbor.drinkingWater', formikId: 'harborServiceDrinkingWater', formikVar: 'formik.values.harborServiceDrinkingWater'},
  'electricity': {labelId: 'service.harbor.electricity', formikId: 'harborServiceElectricity', formikVar: 'formik.values.harborServiceElectricity'},
  'shower': {labelId: 'service.harbor.shower', formikId: 'harborServiceShower', formikVar: 'formik.values.harborServiceShower'},
  'sauna': {labelId: 'service.harbor.sauna', formikId: 'harborServiceSauna', formikVar: 'formik.values.harborServiceSauna'},
  'toilet': {labelId: 'service.harbor.toilet', formikId: 'harborServiceToilet', formikVar: 'formik.values.harborServiceToilet'},
  'laundryRoom': {labelId: 'service.harbor.laundryRoom', formikId: 'harborServiceLaundryRoom', formikVar: 'formik.values.harborServiceLaundryRoom'},
  'dishwashingPoint': {labelId: 'service.harbor.dishwashingPoint', formikId: 'harborServiceDishwashingPoint', formikVar: 'formik.values.harborServiceDishwashingPoint'},
  'barbecueArea': {labelId: 'service.harbor.barbecueArea', formikId: 'harborServiceBarbecueArea', formikVar: 'formik.values.harborServiceBarbecueArea'},
};

const boatUpkeepServices = {
  'diesel': { labelId: 'service.boatUpkeep.diesel', formikId: 'upkeepServiceDiesel', formikVar: 'formik.values.upkeepServiceDiesel' },
  'gasoline': { labelId: 'service.boatUpkeep.gasoline', formikId: 'upkeepServiceGasoline', formikVar: 'formik.values.upkeepServiceGasoline' },
  'fuelAvailability24h': { labelId: 'service.boatUpkeep.fuelAvailability24h', formikId: 'upkeepServiceFuelAvailability24h', formikVar: 'formik.values.upkeepServiceFuelAvailability24h' },
  'LPG': { labelId: 'service.boatUpkeep.LPG', formikId: 'upkeepServiceLPG', formikVar: 'formik.values.upkeepServiceLPG' },
  'septicDisposal': { labelId: 'service.boatUpkeep.septicDisposal', formikId: 'upkeepServiceSepticDisposal', formikVar: 'formik.values.upkeepServiceSepticDisposal' },
  'chemicalToiletDisposal': { labelId: 'service.boatUpkeep.chemicalToiletDisposal', formikId: 'upkeepServiceChemicalToiletDisposal', formikVar: 'formik.values.upkeepServiceChemicalToiletDisposal' },
  'garbageReception': { labelId: 'service.boatUpkeep.garbageReception', formikId: 'upkeepServiceGarbageReception', formikVar: 'formik.values.upkeepServiceGarbageReception' },
  'recyclingPoint': { labelId: 'service.boatUpkeep.recyclingPoint', formikId: 'upkeepServiceRecyclingPoint', formikVar: 'formik.values.upkeepServiceRecyclingPoint' },
  'mastCrane': { labelId: 'service.boatUpkeep.mastCrane', formikId: 'upkeepServiceMastCrane', formikVar: 'formik.values.upkeepServiceMastCrane' },
  'boatRepairs': { labelId: 'service.boatUpkeep.boatRepairs', formikId: 'upkeepServiceBoatRepairs', formikVar: 'formik.values.upkeepServiceBoatRepairs' },
  'engineRepairs': { labelId: 'service.boatUpkeep.engineRepairs', formikId: 'upkeepServiceEngineRepairs', formikVar: 'formik.values.upkeepServiceEngineRepairs' },
  'ramp': { labelId: 'service.boatUpkeep.ramp', formikId: 'upkeepServiceRamp', formikVar: 'formik.values.upkeepServiceRamp' },
  'boatingSupplies': { labelId: 'service.boatUpkeep.boatingSupplies', formikId: 'upkeepServiceBoatingSupplies', formikVar: 'formik.values.upkeepServiceBoatingSupplies' },
};

const amenityServices ={
  'restaurant': { labelId: 'service.amenities.restaurant', formikId: 'amenityServicesRestaurant', formikVar: 'formik.values.amenityServicesRestaurant' },
  'cafe': { labelId: 'service.amenities.cafe', formikId: 'amenityServicesCafe', formikVar: 'formik.values.amenityServicesCafe' },
  'groceryStore': { labelId: 'service.amenities.groceryStore', formikId: 'amenityServicesGroceryStore', formikVar: 'formik.values.amenityServicesGroceryStore' },
  'pharmacy': { labelId: 'service.amenities.pharmacy', formikId: 'amenityServicesPharmacy', formikVar: 'formik.values.amenityServicesPharmacy' },
  'liquorStore': { labelId: 'service.amenities.liquorStore', formikId: 'amenityServicesLiquorStore', formikVar: 'formik.values.amenityServicesLiquorStore' },
  'ATM': { labelId: 'service.amenities.ATM', formikId: 'amenityServicesATM', formikVar: 'formik.values.amenityServicesATM' },
  'bank': { labelId: 'service.amenities.bank', formikId: 'amenityServicesBank', formikVar: 'formik.values.amenityServicesBank' },
  'postalOffice': { labelId: 'service.amenities.postalOffice', formikId: 'amenityServicesPostalOffice', formikVar: 'formik.values.amenityServicesPostalOffice' },
  'playground': { labelId: 'service.amenities.playground', formikId: 'amenityServicesPlayground', formikVar: 'formik.values.amenityServicesPlayground' },
  'healthServices': { labelId: 'service.amenities.healthServices', formikId: 'amenityServicesHealthServices', formikVar: 'formik.values.amenityServicesHealthServices' },
  'vet': { labelId: 'service.amenities.vet', formikId: 'amenityServicesVet', formikVar: 'formik.values.amenityServicesVet' },
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#C0C0C0',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#555555',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#203CCC'
};

const acceptStyle = {
  borderColor: '#008445'
};

const rejectStyle = {
  borderColor: '#FF4C23'
};

function StyledDropzone(props) {
  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {'image/jpeg': ['.jpg', '.JPG', '.jpeg', '.JPEG']},
    maxSize: 5242880*2,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        props.setImageFile(null);
      //   enqueueSnackbar('Varmista, että tiedosto on oikeassa muodossa ja kooltaan alle 5 MB.', {
      //     variant: 'error',
      //  })  
      };
      if (acceptedFiles[0]) {
        props.setImageFile(Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0])}));
      }
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Vedä kuvatiedosto (jpeg) tähän, tai klikkaa valitaksesi tiedostoista. Kuvan resoluution tulisi olla vähintään 1280 x 960 pikseliä ja enimmäiskoko on 20 MB. Kuva rajataan kuvasuhteeseen 4:3, joten suosi vaakakuvia. Lisää yksi kuva kerrallaan.</p>
      </div>
      <Box display="flex" justifyContent="center" alignItems="center" width='100%' mt={2}>
        {props.imageFile && <img src={props.imageFile.preview} width='100px' alt='ladattava kuva'/>}
      </Box>
    </div>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ManagerServiceItem = (props) => {
  const { label, formik, formikId, formikVal } = props;

  if (!label) return null;
  return (
    <Grid item xs={6} container sx={{ alignItems: "center" }} justifyContent="left">
      <FormControlLabel
        control={<Checkbox checked={formikVal} />}
        label={label}
        name={formikId}
        onChange={formik.handleChange}
      />
    </Grid>
  );
}

const ManagerServiceItemList = ({ itemConfig, intl, formik }) => {
  var body = [];
  const { formatMessage } = intl;
  
  for (let [key, value] of Object.entries(itemConfig)) {
    var localizedLabel = key;
    const labelId = itemConfig[key]?.labelId;
    localizedLabel = labelId ? formatMessage({ id: itemConfig[key].labelId}) : key;
    const formikId = itemConfig[key].formikId;
    body.push(<ManagerServiceItem key={key} label={localizedLabel} formik={formik} formikVal={formik.values[formikId]} formikId={formikId}/>);
  }

  return body;
}

const HarborManagerModal = (props) => {
  const { harborInfo, harborManagerModalOpen, setHarborManagerModalOpen, fetchHarbors } = props;
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [addImageActive, setAddImageActive] = useState(false);
  const handleSetAddImageActive = () => {
    if (!auth.user) {
      return false;
    }
    setAddImageActive(true);
    setTimeout(() => {photoUploadRef.current.scrollIntoView()}, 100);
  }
  const photoUploadRef = useRef(null);
  const handleClose = () => setHarborManagerModalOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [harbor, setHarbor] = useState(harborInfo); 
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageFile, _setImageFile] = useState(undefined);

  const setImageFile = (img) => {
    if (imageFile && imageFile.preview) URL.revokeObjectURL(imageFile.preview);
    _setImageFile(img);
  }

  const loadHarbor = () => {
    setInitializing(true);
    verttiApi.get(`harbor/${harbor.publicId}`)
    .then((res) => {
      setHarbor(res.data);
      setInitializing(false);
    })
    .catch((err) => {
      console.error(err);
      setInitializing(false)
    })
  }

  // Load full harbor details
  useEffect(() => {
    loadHarbor();
  }, [])

  const harborFormik = useFormik({
    //validationSchema: requestSchema,
    initialValues: {
      name: harbor?.name ?? '',
      lng: harbor?.lngLat?.[0] ?? '',
      lat: harbor?.lngLat?.[1] ?? '',
      location: harbor?.location ?? '',
      preview: harbor?.preview ?? '',
      approachGuide: harbor?.approachGuide ?? '',
      mooringGuide: harbor?.mooringGuide ?? '',
      fees: harbor?.fees ?? '',
      opennessToWinds: harbor?.opennessToWinds ?? '',
      category: harbor?.category ?? '',

      serviceProviderName: harbor?.serviceProvider?.name ?? '',
      serviceProviderHarbourMaster: harbor?.serviceProvider?.harbourMaster ?? '',
      serviceProviderEmail: harbor?.serviceProvider?.email ?? '',
      serviceProviderPhone: harbor?.serviceProvider?.phone ?? '',
      serviceProviderWww: harbor?.serviceProvider?.www ?? '',
  
      harborServiceDrinkingWater: harbor?.services?.harbor?.drinkingWater === 1,
      harborServiceElectricity: harbor?.services?.harbor?.electricity === 1,
      harborServiceShower: harbor?.services?.harbor?.shower === 1,
      harborServiceSauna: harbor?.services?.harbor?.sauna === 1,
      harborServiceToilet: harbor?.services?.harbor?.toilet === 1,
      harborServiceLaundryRoom: harbor?.services?.harbor?.laundryRoom === 1,
      harborServiceDishwashingPoint: harbor?.services?.harbor?.dishwashingPoint === 1,
      harborServiceBarbecueArea: harbor?.services?.harbor?.barbecueArea === 1,
  
      upkeepServiceDiesel: harbor?.services?.boatUpkeep?.diesel === 1,
      upkeepServiceGasoline: harbor?.services?.boatUpkeep?.gasoline === 1,
      upkeepServiceFuelAvailability24h: harbor?.services?.boatUpkeep?.fuelAvailability24h === 1,
      upkeepServiceLPG: harbor?.services?.boatUpkeep?.LPG === 1,
      upkeepServiceSepticDisposal: harbor?.services?.boatUpkeep?.septicDisposal === 1,
      upkeepServiceChemicalToiletDisposal: harbor?.services?.boatUpkeep?.chemicalToiletDisposal === 1,
      upkeepServiceGarbageReception: harbor?.services?.boatUpkeep?.garbageReception === 1,
      upkeepServiceRecyclingPoint: harbor?.services?.boatUpkeep?.recyclingPoint === 1,
      upkeepServiceMastCrane: harbor?.services?.boatUpkeep?.mastCrane === 1,
      upkeepServiceBoatRepairs: harbor?.services?.boatUpkeep?.boatRepairs === 1,
      upkeepServiceEngineRepairs: harbor?.services?.boatUpkeep?.engineRepairs === 1,
      upkeepServiceRamp: harbor?.services?.boatUpkeep?.ramp === 1,
      upkeepServiceBoatingSupplies: harbor?.services?.boatUpkeep?.boatingSupplies === 1,
      
      amenityServicesRestaurant: harbor?.services?.amenities?.restaurant === 1,
      amenityServicesCafe: harbor?.services?.amenities?.cafe === 1,
      amenityServicesGroceryStore: harbor?.services?.amenities?.groceryStore === 1,
      amenityServicesPharmacy: harbor?.services?.amenities?.pharmacy === 1,
      amenityServicesLiquorStore: harbor?.services?.amenities?.liquorStore === 1,
      amenityServicesATM: harbor?.services?.amenities?.ATM === 1,
      amenityServicesBank: harbor?.services?.amenities?.bank === 1,
      amenityServicesPostalOffice: harbor?.services?.amenities?.postalOffice === 1,
      amenityServicesPlayground: harbor?.services?.amenities?.playground === 1,
      amenityServicesHealthServices: harbor?.services?.amenities?.healthServices === 1,
      amenityServicesVet: harbor?.services?.amenities?.vet === 1,      
    },
    enableReinitialize: true,
    onSubmit: values => {    
      updateHarbor(values);
    }
  });
  

  const uploadImage = () => {
    setUploading(true);
    setProgress(0);
    const config = { 
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    };
    let fd = new FormData();

    fd.append('image', imageFile);

    verttiApi.post(`/photo/upload/${harbor.publicId}`, fd, config)
      .then((res) => {
        loadHarbor();
        setUploading(false);
        setAddImageActive(false);
        setImageFile(null);
        URL.revokeObjectURL(imageFile.preview);
        enqueueSnackbar('Kiitos, kuva lisätty!', {
          variant: 'success',
        });        
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Kuvan tallennus epäonnistui.', {
          variant: 'error',
        })
        setImageFile(null);
        setUploading(false);
      })
  };


  const updateHarbor = (values) => {
    //console.log(values);
    setLoading(true);

    const body = {
      name: values.name,
      location: values.location,
      lngLat: [values.lng, values.lat],
      fees: values.fees,
      preview: values.preview,
      approachGuide: values.approachGuide,
      mooringGuide: values.mooringGuide,
      opennessToWinds: values.opennessToWinds,
      category: values.category,
      serviceProvider: {
        name: values.serviceProviderName,
        email: values.serviceProviderEmail,
        harbourMaster: values.serviceProviderHarbourMaster,
        phone: values.serviceProviderPhone,
        www: values.serviceProviderWww,
      },
      services: {
        harbor: {
          barbecueArea: values.harborServiceBarbecueArea,
          dishwashingPoint: values.harborServiceDishwashingPoint,
          drinkingWater: values.harborServiceDrinkingWater,
          electricity: values.harborServiceElectricity,
          laundryRoom: values.harborServiceLaundryRoom,
          sauna: values.harborServiceSauna,
          shower: values.harborServiceShower,
          toilet: values.harborServiceToilet,
        },
        boatUpkeep: {
          LPG: values.upkeepServiceLPG,
          boatRepairs: values.upkeepServiceBoatRepairs,
          boatingSupplies: values.upkeepServiceBoatingSupplies,
          chemicalToiletDisposal: values.upkeepServiceChemicalToiletDisposal,
          diesel: values.upkeepServiceDiesel,
          engineRepairs: values.upkeepServiceEngineRepairs,
          fuelAvailability24h: values.upkeepServiceFuelAvailability24h,
          garbageReception: values.upkeepServiceGarbageReception,
          gasoline: values.upkeepServiceGasoline,
          mastCrane: values.upkeepServiceMastCrane,
          ramp: values.upkeepServiceRamp,
          recyclingPoint: values.upkeepServiceRecyclingPoint,
          septicDisposal: values.upkeepServiceSepticDisposal,
        },
        amenities: {
          ATM: values.amenityServicesATM,
          bank: values.amenityServicesBank,
          cafe: values.amenityServicesCafe,
          groceryStore: values.amenityServicesGroceryStore,
          healthServices: values.amenityServicesHealthServices,
          liquorStore: values.amenityServicesLiquorStore,
          pharmacy: values.amenityServicesPharmacy,
          playground: values.amenityServicesPlayground,
          postalOffice: values.amenityServicesPostalOffice,
          restaurant: values.amenityServicesRestaurant,
          vet: values.amenityServicesVet,
        },
      }
    }

    verttiApi.patch(`harbor/${harbor.publicId}`, body)
      .then((res) => {
        fetchHarbors({ fetchAll: true });
        enqueueSnackbar('Sataman tiedot tallennettu.', { variant: 'success' });
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error updating harbor', err);
        enqueueSnackbar('Tietojen päivitys epäonnistui.', { variant: 'error' });
        setLoading(false);
      }) 

  }

  const removePhoto = (photoId) => {
    const newPhotos = harbor.photos.filter(p => p.publicId !== photoId)
  
    const newHarbor = {
      ...harbor,
      photos: newPhotos,
    };
  
    setHarbor(newHarbor);
  };


  // if (!harbor.services) return null;
  // if (initializing) return null;

  return (
    <BootstrapDialog
        fullScreen={fullScreen}
        maxWidth='md'
        open={harborManagerModalOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
      >
      <form onSubmit={harborFormik.handleSubmit}>
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={handleClose}>
          Muokkaa satamaa: {harbor.name}
        </BootstrapDialogTitle>
        <DialogContent dividers>

        {harbor.photos && harbor.photos.length > 0 && (
            <>
              <ImageCarouselHarborAdmin images={harbor.photos} removePhoto={removePhoto} />
              <Box display='flex' justifyContent='flex-end'>
                {!addImageActive && <Button size='small' onClick={() => handleSetAddImageActive(true)}>Lisää kuva</Button>}
              </Box>
            </>
          )}
          
          {(!harbor.photos || harbor.photos.length === 0) && (
            <>
              <Alert variant='info'>
                Meillä ei ole vielä kuvia kohteesta. Olisiko sinulla lisätä ensimmäinen?
              </Alert>
              {!addImageActive && (
                <Box display='flex' justifyContent='center'>
                  <Button size='small' variant='contained' onClick={() => handleSetAddImageActive(true)}>Lisää kuva</Button>
                </Box>
              )}
            </>
          )}

          <Box ref={photoUploadRef} />
            {addImageActive && (
              <Grow in={true}>
                <Box my={3}>
                  <StyledDropzone setImageFile={setImageFile} imageFile={imageFile}/>
                  {uploading && <Box mt={2}><LinearProgress variant="determinate" value={progress} /></Box>}
                  <Box mt={1} display='flex' justifyContent='flex-end'>
                    <Button 
                      disabled={!imageFile || uploading} 
                      onClick={() => uploadImage()} 
                      size='small' 
                      variant='contained'
                    >
                      {uploading ? 'Tallennetaan...' : 'Tallenna kuva'}
                    </Button>
                  </Box>
                </Box>
              </Grow>
            )}

          <Box mt={1} />
          {!auth.isAdmin() && (
            <Alert severity='info'>Jos haluat muuttaa sataman kategoriaa tai sijaintia, ota yhteys asiakaspalveluun osoitteessa posti@vertti.io.</Alert>
          )}
          {auth.isAdmin() && (
            <Box pt={3} >
              <FormControl>
                <FormLabel id="harbor-category-group-label">Sataman tyyppi</FormLabel>
                <RadioGroup
                  id='category'
                  aria-labelledby="harbor-category-group-label"
                  value={harborFormik.values.category}
                  onChange={harborFormik.handleChange}
                  name="radio-buttons-group"
                >
                  <FormControlLabel name='category' value="guest_harbor" control={<Radio />} label="Vierassatama" />
                  <FormControlLabel name='category' value="service_harbor" control={<Radio />} label="Palvelusatama" />
                  <FormControlLabel name='category' value="excursion_harbor" control={<Radio />} label="Retkisatama" />
                  <FormControlLabel name='category' value="natural_harbor" control={<Radio />} label="Luonnonsatama" />
                  <FormControlLabel name='category' value="home_harbor" control={<Radio />} label="Kotisatama (ei vieraspaikkoja)" />
                </RadioGroup>
              </FormControl>
            </Box>
          )}
          {auth.isAdmin() && (
            <React.Fragment>
            <Box pt={3} >
              <TextField
                id='lng'
                label='Longitudi'
                variant='standard'
                onChange={harborFormik.handleChange}
                onBlur={harborFormik.handleBlur}
                value={harborFormik.values.lng}
                sx={{width: '100%'}}/>
            </Box>
            <Box pt={3} >
              <TextField
                id='lat'
                label='Latitudi'
                variant='standard'
                onChange={harborFormik.handleChange}
                onBlur={harborFormik.handleBlur}
                value={harborFormik.values.lat}
                sx={{width: '100%'}}/>
            </Box>
            </React.Fragment>
          )}
          <Box pt={3} >
            <TextField
              id='name'
              label='Sataman nimi'
              variant='standard'
              onChange={harborFormik.handleChange}
              onBlur={harborFormik.handleBlur}
              value={harborFormik.values.name}
              sx={{width: '100%'}}/>
          </Box>

          <Box pt={3} >
            <TextField
              id='location'
              label='Sataman sijainti'
              variant='standard'
              onChange={harborFormik.handleChange}
              onBlur={harborFormik.handleBlur}
              value={harborFormik.values.location}
              sx={{width: '100%'}}/>
          </Box>

          <Box pt={3} >
            <TextField
              id='preview'
              label='Yleisesittely'
              variant='standard'
              multiline
              onChange={harborFormik.handleChange}
              onBlur={harborFormik.handleBlur}
              value={harborFormik.values.preview}
              sx={{width: '100%'}}/>
          </Box>
          <Box pt={3} >
            <TextField
              id='approachGuide'
              label='Lähestymisohje'
              variant='standard'
              multiline
              onChange={harborFormik.handleChange}
              onBlur={harborFormik.handleBlur}
              value={harborFormik.values.approachGuide}
              sx={{width: '100%'}}/>
          </Box>

          <Box pt={3} >
            <TextField
              id='mooringGuide'
              label='Kiinnittymisohje'
              variant='standard'
              multiline
              onChange={harborFormik.handleChange}
              onBlur={harborFormik.handleBlur}
              value={harborFormik.values.mooringGuide}
              sx={{width: '100%'}}/>
          </Box>

          <Box pt={3} >
            <TextField
              id='fees'
              label='Tietoa satamamaksuista'
              variant='standard'
              onChange={harborFormik.handleChange}
              onBlur={harborFormik.handleBlur}
              value={harborFormik.values.fees}
              sx={{width: '100%'}}/>
          </Box>

          <Box pt={3} >
            <TextField
              id='opennessToWinds'
              label='Avoimuus tuulille'
              variant='standard'
              onChange={harborFormik.handleChange}
              onBlur={harborFormik.handleBlur}
              value={harborFormik.values.opennessToWinds}
              sx={{width: '100%'}}/>
          </Box>
            <Box mt={3}>
              <Typography variant="subtitle2" display="block">
                Sataman palvelut
              </Typography>
            </Box>

            <Grid container spacing={1} justifyContent="flex-start">
              <ManagerServiceItemList itemConfig={harborServices} intl={props.intl} formik={harborFormik}/>
            </Grid>

            <Box mt={3}>
              <Typography variant="subtitle2" display="block" >
                Veneen ylläpitopalvelut
              </Typography>

            <Grid container spacing={1} justifyContent="flex-start">
              <ManagerServiceItemList itemConfig={boatUpkeepServices} intl={props.intl} formik={harborFormik}/>
            </Grid>

            </Box>

            <Box mt={3}>
              <Typography variant="subtitle2" display="block">
                Miehistön palvelut
              </Typography>

              <Grid container spacing={1} justifyContent="flex-start">
                <ManagerServiceItemList itemConfig={amenityServices} intl={props.intl} formik={harborFormik}/>
              </Grid>
            </Box>

            <Box mt={3}>
              <Typography variant="subtitle2" display="block">
                Yhteystiedot
              </Typography>

              <Box pt={3} >
                <TextField
                  id='serviceProviderName'
                  label='Palveluntarjoaja'
                  variant='standard'
                  onChange={harborFormik.handleChange}
                  onBlur={harborFormik.handleBlur}
                  value={harborFormik.values.serviceProviderName}
                  sx={{width: '100%'}}/>
              </Box>
              <Box pt={3} >
                <TextField
                  id='serviceProviderHarbourMaster'
                  label='Yhteyshenkilö'
                  variant='standard'
                  onChange={harborFormik.handleChange}
                  onBlur={harborFormik.handleBlur}
                  value={harborFormik.values.serviceProviderHarbourMaster}
                  sx={{width: '100%'}}/>
              </Box>
              <Box pt={3} >
                <TextField
                  id='serviceProviderPhone'
                  label='Puhelinnumero'
                  variant='standard'
                  onChange={harborFormik.handleChange}
                  onBlur={harborFormik.handleBlur}
                  value={harborFormik.values.serviceProviderPhone}
                  sx={{width: '100%'}}/>
              </Box>
              <Box pt={3} >
                <TextField
                  id='serviceProviderEmail'
                  label='Sähköposti'
                  variant='standard'
                  onChange={harborFormik.handleChange}
                  onBlur={harborFormik.handleBlur}
                  value={harborFormik.values.serviceProviderEmail}
                  sx={{width: '100%'}}/>
              </Box>
              <Box pt={3} >
                <TextField
                  id='serviceProviderWww'
                  label='Web'
                  variant='standard'
                  onChange={harborFormik.handleChange}
                  onBlur={harborFormik.handleBlur}
                  value={harborFormik.values.serviceProviderWww}
                  sx={{width: '100%'}}/>
              </Box>

            </Box>
          
          {auth.user.role === 'admin' && <HarborEntitlementsManager harborId={harbor.publicId}/>}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} type="submit">
            Sulje
          </Button>
          <Button type="submit" disabled={loading}>
            Tallenna
          </Button>
        </DialogActions>
        </form>
      </BootstrapDialog>
  );
}

export default injectIntl(withSnackbar(HarborManagerModal));