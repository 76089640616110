import React, {useState, useEffect} from 'react';
import { Box, Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, Stack, Tabs, Tab, Chip, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useAuth } from '../../hooks/useAuth';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AnchorIcon from '@mui/icons-material/Anchor';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import verttiApi from '../../api/verttiApi';
import ImageCarousel from './ImageCarousel';
import { round } from '@turf/helpers';
import { useNavigate } from "react-router-dom";

import noimage from '../../assets/img/placeholders/noimage.png';

const harborServices = {
  'drinkingWater': {labelId: 'service.harbor.drinkingWater'},
  'electricity': {labelId: 'service.harbor.electricity'},
  'shower': {labelId: 'service.harbor.shower'},
  'sauna': {labelId: 'service.harbor.sauna'},
  'toilet': {labelId: 'service.harbor.toilet'},
  'laundryRoom': {labelId: 'service.harbor.laundryRoom'},
  'dishwashingPoint': {labelId: 'service.harbor.dishwashingPoint'},
  'barbecueArea': {labelId: 'service.harbor.barbecueArea'},
};

const boatUpkeepServices = {
  'diesel': { labelId: 'service.boatUpkeep.diesel' },
  'gasoline': { labelId: 'service.boatUpkeep.gasoline' },
  'fuelAvailability24h': { labelId: 'service.boatUpkeep.fuelAvailability24h' },
  'LPG': { labelId: 'service.boatUpkeep.LPG' },
  'septicDisposal': { labelId: 'service.boatUpkeep.septicDisposal' },
  'chemicalToiletDisposal': { labelId: 'service.boatUpkeep.chemicalToiletDisposal' },
  'garbageReception': { labelId: 'service.boatUpkeep.garbageReception' },
  'recyclingPoint': { labelId: 'service.boatUpkeep.recyclingPoint' },
  'mastCrane': { labelId: 'service.boatUpkeep.mastCrane' },
  'boatRepairs': { labelId: 'service.boatUpkeep.boatRepairs' },
  'engineRepairs': { labelId: 'service.boatUpkeep.engineRepairs' },
  'ramp': { labelId: 'service.boatUpkeep.ramp' },
  'boatingSupplies': { labelId: 'service.boatUpkeep.boatingSupplies' },
};

const amenityServices ={
  'restaurant': { labelId: 'service.amenities.restaurant' },
  'cafe': { labelId: 'service.amenities.cafe' },
  'groceryStore': { labelId: 'service.amenities.groceryStore' },
  'pharmacy': { labelId: 'service.amenities.pharmacy' },
  'liquorStore': { labelId: 'service.amenities.liquorStore' },
  'ATM': { labelId: 'service.amenities.ATM' },
  'bank': { labelId: 'service.amenities.bank' },
  'postalOffice': { labelId: 'service.amenities.postalOffice' },
  'playground': { labelId: 'service.amenities.playground' },
  'healthServices': { labelId: 'service.amenities.healthServices' },
  'vet': { labelId: 'service.amenities.vet' },
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ServiceItem = (props) => {
  const { label, value } = props;
  var fullLabel = '';

  if (!label) return null;

  if (value > 1) {
    const kmDistance = round(value / 1000, 1);
    fullLabel = `${label} (${kmDistance} km)`;
  }
  else {
    fullLabel = label;
  }

  return (
    <Grid item xs={6} container sx={{ alignItems: "center" }} justifyContent="left">
      {value > 0 && <CheckCircleOutlineSharpIcon fontSize="small"/> }
      {parseInt(value) === 0 && <RadioButtonUncheckedSharpIcon color="disabled" fontSize="small" /> }
      <Typography ml={1} variant="caption">
        {fullLabel}
      </Typography>
    </Grid>
  );
}

const ServiceItemList = ({ itemConfig, itemData, intl }) => {
  var body = [];
  const { formatMessage } = intl;

  for (let [key, value] of Object.entries(itemConfig)) {
    var localizedLabel = key;
    const labelId = itemConfig[key]?.labelId;
    localizedLabel = labelId ? formatMessage({ id: itemConfig[key].labelId}) : key;
    //console.log(key, localizedLabel, itemData[key]);
    body.push(<ServiceItem key={key} label={localizedLabel} value={itemData[key]} />);
  }

  return body;
}

const displayCoordinate = (value, latlng) => {
  const number = Math.abs(value); // Change to positive
  const integerPart = Math.floor(number);
  const decimalPart =  number - Math.floor(number);
  const minutes = round(decimalPart * 60, 2);
  var postfix;
  // FIXME: handle western and southern coordinates
  if (latlng === 'lat') postfix = 'N';
  else postfix = 'E';
  return `${integerPart}° ${minutes.toLocaleString('fi-FI')}' ${postfix}`;
};

const getCategoryLabel = (category) => {
  switch (category) {
    case 'guest_harbor': return 'Vierassatama';
    case 'service_harbor': return 'Palvelusatama';
    case 'excursion_harbor': return 'Retkisatama';
    case 'natural_harbor': return 'Luonnonsatama';
    case 'home_harbor': return 'Kotisatama';
    default: return '';
  }
}

const HarborLinks = ({ serviceProvider }) => {
  const navigateToExternalSite = (url) => {
    console.log('foo')
    window.open(url, '_blank', 'noopener');
  };

  const TikTokIcon = ({ color = "#73716F" }) => {
    return (
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="38"
        height="38"
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };

  return (
    <Box>
      <Stack direction='row' spacing='1'>
        {serviceProvider.socialFacebook && <IconButton onClick={() => navigateToExternalSite(serviceProvider.socialFacebook)} size='large'><FacebookIcon fontSize='large'/></IconButton>}
        {serviceProvider.socialInstagram && <IconButton onClick={() => navigateToExternalSite(serviceProvider.socialInstagram)} size='large'><InstagramIcon fontSize='large'/></IconButton>}
        {serviceProvider.socialX && <IconButton onClick={() => navigateToExternalSite(serviceProvider.socialX)} size='large'><XIcon fontSize='large'/></IconButton>}
        {serviceProvider.socialTiktok && <IconButton onClick={() => navigateToExternalSite(serviceProvider.socialTiktok)} size='large'><TikTokIcon /></IconButton>}
        {serviceProvider.socialYoutube && <IconButton onClick={() => navigateToExternalSite(serviceProvider.socialYoutube)} size='large'><YouTubeIcon fontSize='large'/></IconButton>}
      </Stack>
    </Box>
  );
}

const HarborModal = (props) => {
  let auth = useAuth();
  let navigate = useNavigate();
  const { harborInfo, harborModalOpen, setHarborModalOpen, displayHarborManager, displayDialog } = props;
  const [harbor, setHarbor] = useState(harborInfo); 
  const handleClose = () => setHarborModalOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); 
  const [selectedTab, setSelectedTab] = useState("0");
  const [initializing, setInitializing] = useState(true);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const loadHarbor = () => {
    setInitializing(true);
    verttiApi.get(`harbor/${harbor.publicId}`)
    .then((res) => {
      console.log(res.data);
      setHarbor(res.data);
      setInitializing(false);
    })
    .catch((err) => {
      console.error(err);
      setInitializing(false)
    })
  }

  const updatePhotoLike = (photoId, newStatus) => {
    const newPhotos = harbor.photos.map((photo) => {
      if (photo.publicId === photoId) {
        return {
          ...photo,
          liked: newStatus,
        };
      }
      return photo;
    });
  
    const newHarbor = {
      ...harbor,
      photos: newPhotos,
    };
  
    setHarbor(newHarbor);
  };

  // Load full harbor details
  useEffect(() => {
    loadHarbor();
    return () => {
      setHarbor(null);
    }
  }, [])

  // if (!harbor.services) return (
  //   <BootstrapDialog
  //       fullScreen={fullScreen}
  //       maxWidth='md'
  //       PaperProps={{ sx: { bgcolor: '#FBF6F2', position: 'fixed', top: 0 } }}
  //       open={harborModalOpen}
  //       onClose={handleClose}
  //       aria-labelledby="responsive-dialog-title"
  //       scroll="paper"
  //       fullWidth
  //     />
  // );

  return (
    <BootstrapDialog
        fullScreen={fullScreen}
        maxWidth='md'
        PaperProps={{ sx: { bgcolor: '#FBF6F2', position: 'fixed', top: 0 } }}
        open={harborModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
        fullWidth
      >
        <BootstrapDialogTitle id="responsive-dialog-title" onClose={handleClose}>
          {harborInfo.name} {harborInfo.location && `(${harborInfo.location})`}
          {/* <Tooltip title='Lisää reittisuunnitelmaan'>
            <IconButton onClick={() => {addHarborToRoute(); handleClose()}} color="primary" aria-label="Lisää satama reittisuunnitelmaan">
              <AddCircleIcon />
            </IconButton>
          </Tooltip> */}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box>
            <Tabs value={selectedTab} onChange={handleTabChange} centered>
              <Tab label="Esittely" value="0"/>
              <Tab label="Palvelut" value="1" />
              <Tab label="Yhteystiedot" value="2" />
            </Tabs>
            
            <Box sx={{ padding: {xs: 0, md: 2} }}>
              {selectedTab === "0" && (
                <>
                  {initializing && (
                    <Box my={5}>
                      <LinearProgress />
                    </Box>
                  )}

                  {!initializing && harbor?.photos && harbor.photos?.length > 0 && (
                    <>
                      <ImageCarousel images={harbor.photos} updatePhotoLike={updatePhotoLike} displayDialog={displayDialog} />
                    </>
                  )}

                  <Grid container>
                    {/* <Grid item xs={12} */}
                    <Grid item md={12} xs={12} p={0}>
                      <Stack spacing={2} direction={{xs: 'column', md: 'row'}} mb={2}>
                        <Chip icon={<AnchorIcon fontSize="small" />} label={getCategoryLabel(harborInfo.category)} />
                        <Chip icon={<GpsFixedIcon fontSize="small" />} label={`${displayCoordinate(harborInfo.lngLat[1], 'lat')} ${displayCoordinate(harborInfo.lngLat[0], 'lng')}`} />
                      </Stack>

                      <Typography variant="body1" gutterBottom>{harborInfo.preview}</Typography>

                      {harborInfo.approachGuide && (
                        <Typography variant="subtitle2" display="block">
                          Lähestymisohje
                        </Typography>
                        )}
                      <Typography variant="body2" gutterBottom>{harborInfo.approachGuide}</Typography>

                      {harborInfo.mooringGuide && (
                        <Typography variant="subtitle2" display="block">
                          Kiinnittymisohje
                        </Typography>
                      )}
                      <Typography variant="body2" gutterBottom>{harborInfo.mooringGuide}</Typography>

                      {harborInfo.opennessToWinds && (
                        <Typography variant="subtitle2" display="block">
                          Sataman suojaisuus
                        </Typography>
                      )}
                      <Typography variant="body2" gutterBottom>{harborInfo.opennessToWinds}</Typography>

                    </Grid>
                    <Grid item md={6} xs={12} pt={{xs: 2, md: 0}}>
                      {/* <Box textAlign='right'>
                        <img 
                          src={`https://vertiles.fra1.cdn.digitaloceanspaces.com/img/harbor/orto/${harborInfo['_id']}.jpeg`} 
                          width='100%'
                          style={{borderRadius: '3%', border: '8px solid white'}}
                          alt='Ilmakuva'
                          onError={(e) => {
                            e.target.src = noimage
                          }}
                          />
                        <Typography variant='body2'>Kuva &copy; Maanmittauslaitos</Typography>
                      </Box>             */}
                    </Grid>
                  </Grid>
                </>
              )}

              {selectedTab === "1" && (
                <>
                  <Box mt={3}>
                    <Typography variant="subtitle2" display="block">
                      Sataman palvelut
                    </Typography>
                  </Box>

                  <Grid container spacing={1} justifyContent="flex-start">
                    <ServiceItemList itemConfig={harborServices} itemData={harborInfo.services.harbor} intl={props.intl}/>
                  </Grid>

                  <Box mt={3}>
                    <Typography variant="subtitle2" display="block" >
                      Veneen ylläpitopalvelut
                    </Typography>

                    <Grid container spacing={1} justifyContent="flex-start">
                      <ServiceItemList itemConfig={boatUpkeepServices} itemData={harborInfo.services.boatUpkeep} intl={props.intl}/>
                    </Grid>
                  </Box>

                  <Box mt={3}>
                    <Typography variant="subtitle2" display="block">
                      Miehistön palvelut
                    </Typography>

                    <Grid container spacing={1} justifyContent="flex-start">
                      <ServiceItemList itemConfig={amenityServices} itemData={harborInfo.services.amenities} intl={props.intl}/>
                    </Grid>
                  </Box>
                </>
              )}

              {selectedTab === "2" && (
                <>
                  <Box mt={3}>
                    <Typography variant="subtitle2" display="block">
                      Satama somessa
                    </Typography>
                    <HarborLinks serviceProvider={harborInfo.serviceProvider} />
                    <Typography variant="subtitle2" display="block">
                      Yhteystiedot
                    </Typography>
                    {harborInfo.serviceProvider.name && (<Typography variant="body2" gutterBottom>Palveluntarjoaja: {harborInfo.serviceProvider.name}</Typography>)}
                    {harborInfo.serviceProvider.harbourMaster && (<Typography variant="body2" gutterBottom>Satamamestari: {harborInfo.serviceProvider.harbourMaster}</Typography>)}
                    {harborInfo.serviceProvider.phone && (<Typography variant="body2" gutterBottom>Puhelin: {harborInfo.serviceProvider.phone}</Typography>)}
                    {harborInfo.serviceProvider.email && (<Typography variant="body2" gutterBottom>Sähköposti: {harborInfo.serviceProvider.email}</Typography>)}
                    {harborInfo.serviceProvider.www && (<Typography variant="body2" gutterBottom>Kotisivu: <a href={harborInfo.serviceProvider.www} target="_blank" rel="noopener">{harborInfo.serviceProvider.www}</a></Typography>)}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {auth?.user && auth.isAuthorizedToHarbor(harborInfo.publicId) &&
            <Button onClick={() => navigate(`/manager/harbor/${harborInfo.publicId}`, {replace: true})}>
              Muokkaa
            </Button>
          }
          {/* <Button onClick={() => {addHarborToRoute(); handleClose()}} autoFocus>
            Lisää reittisuunnitelmaan
          </Button> */}
          <Button onClick={handleClose} autoFocus>
            Sulje
          </Button>
        </DialogActions>
      </BootstrapDialog>
  );
}

export default injectIntl(HarborModal);