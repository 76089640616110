import React, {useState, useEffect} from 'react';
// import { useTheme } from '@mui/material/styles';
import verttiApi from '../../../../api/verttiApi';
import Box from '@mui/material/Box';
import { Grid, Card, CardMedia, CardActions, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { Backdrop, Fade, Modal, Tooltip } from '@mui/material';
import { useAuth } from '../../../../hooks/useAuth';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: '100vw', md: '90vw', xl: '80vw'},
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
  m: 0,
};

const ImageToolBar = ({ photo, removePhoto }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const RemovePhotoConfirmationDialog = () => {
  
    const handleClose = () => {
      setConfirmationOpen(false);
    };
  
    const handleConfirm = () => {
      deletePhoto(photo.publicId)
    };
  
    return (
      <div>
        <Dialog
          open={confirmationOpen}
          onClose={handleClose}
          aria-labelledby="vahvistus"
          aria-describedby="vahvista ominaisuuden lisääminen"
        >
          <DialogTitle id="alert-dialog-title">
            {"Vahvista ominaisuuden lisääminen"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Oletko varma, että haluat poistaa kuvan? Kuvan poisto on lopullinen, eikä sitä voi perua.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Peru</Button>
            <Button onClick={handleConfirm}>
              Poista kuva
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const deletePhoto = (publicId) => {
    verttiApi.delete(`/manager/photo/${publicId}`)
    .then(() => {
      removePhoto(publicId);
      setConfirmationOpen(false);
    }) 
    .catch((err) => {
      alert('Poisto epäonnistui');
      setConfirmationOpen(false);
    })
  }

  return (
    <React.Fragment>
      <Box mt={1} display='flex' justifyContent='space-between' width='100%'>
        <Box display='flex'>
          {photo.uploader !== 'Maanmittauslaitos' && (
            <Tooltip title='Poista kuva'>
              <DeleteForeverIcon fontSize='medium' onClick={() => setConfirmationOpen(true)} />
            </Tooltip>
          )}
        </Box>
      </Box>
      <RemovePhotoConfirmationDialog />
    </React.Fragment>
  );
}

const FullScreenImage = ({ url, open, handleClose }) => {
  return (

    <Modal
      aria-labelledby="suuri kuva"
      aria-describedby="suuri kuva"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Box
            component="img"
            sx={{
              maxWidth: '100%',
            }}
            src={url}
            onClick={() => handleClose()}
          />
        </Box>
      </Fade>
    </Modal>
  );
}

const ImageCarouselHarborAdmin = ({ images, removePhoto }) => {
  const [displayImageUrl, setDisplayImageUrl] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleModalClose = () => {
    setModalOpen(false);
  }

  useEffect(() => {
    const imageUrls = images.map(image => `${process.env.REACT_APP_CDN_URL}/${image.url}`);
    const loadImages = imageUrls.map(url => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });
    });
  
    Promise.all(loadImages).then(() => setIsLoading(false)).catch(err => console.log("Error loading images", err));
  }, [images]);

  if (!images ) return <></>;
  if (isLoading) return 'Ladataan kuvia...';

  const displayFullScreen = (url) => {
    setDisplayImageUrl(url);
    setModalOpen(true);
  }

  return (
    <>
    <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={`${process.env.REACT_APP_CDN_URL}/${image.url}`}
                alt=''
                onClick={() => displayFullScreen(`${process.env.REACT_APP_CDN_URL}/${image.url}`)}
              />
              <CardActions>
                <ImageToolBar photo={image} removePhoto={removePhoto}/>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    <FullScreenImage open={modalOpen} handleClose={handleModalClose} url={displayImageUrl} />
    </>
  );
}

export default ImageCarouselHarborAdmin;
